import React from "react"
import Helmet from 'react-helmet'
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
        {/* General tags */}
        <title>404: Page Not found</title>
        <meta name="robots" content="noindex, follow" />
    </Helmet>
    <h1>Sorry we could not find the page you're looking for</h1>
    <p>
        <a href="/" style={{color: `red`}} >Go back to the homepage</a>
    </p>
  </Layout>
)

export default NotFoundPage
